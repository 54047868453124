table {
  border: 1px solid darkgrey;
  border-collapse: collapse;
}

table th {
  padding: 5px;
}

table td {
  padding: 5px;
  text-align: center;
  border: 1px solid #a9a9a9;
}

table th {
  border: 1px solid #a9a9a9;
}

.profile {
  /* display: grid; */
  margin-left: 8%;
  margin-right: 8%;
  grid-template-columns: 4% 10% [content] auto 15% 4%;
}

.profile-title {
  margin-top: 20px;
  font-family: 'EB Garamond';
  font-size: 2rem;
}

.profile_items {
  display: grid;
  grid-column: content;

  padding: 25px;
  margin-top: 30px;

  grid-template-columns: 35% 35% 25%;

  border: solid 0.5px lightgrey;

  grid-row-gap: 25px;
  grid-column-gap: 10px;
}

#profile-welcome-banner {
  display: grid;

  grid-column: content;

  justify-content: center;

  padding: 10px;
  border: 1px solid #0054a6;
  border-radius: 5px;
  font-size: 20px;
}

#profile-welcome-banner a {
  color: #00478f;
  text-decoration: none;
}

#profile-welcome-banner a:hover {
  text-decoration: underline;
}

.text_readings {
  display: grid;

  margin-top: 15px;

  grid-column: 1 / 4;
  grid-row: 2;

  grid-template-rows: 25px auto;
}

.performance {
  display: grid;

  grid-column: 1 / 4;
  grid-row: 4;

  margin-top: 10px;

  grid-template-rows: 25px auto;
}

.preferred_difficulty {
  display: grid;
  grid-column: 1 / 4;
  grid-row: 2;

  grid-template-rows: 25px auto;
}

.difficulty_desc {
  margin-top: 10px;
}

.feedback {
  display: grid;
  grid-row: 5;
  font-size: larger;
  grid-template-columns: auto;
}

.feedback a {
  color: #00478f;
  text-decoration: none;
}

.feedback a:hover {
  text-decoration: underline;
}

.feedback span {
  padding: 0 0 5px 0;
}

#words {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

#dashboard_connect {
  display: grid;
  grid-row: 3;
  grid-column: 2 / 3;
  grid-template-columns: auto;
  grid-row-gap: 10px;
}

#dashboard_connect > .value {
  display: flex;
}

#dashboard_connect > .value > .check-box-text {
  font-size: 18px;
  padding-left: 5px;
}

#research_consent {
  display: grid;
  grid-row: 3;
  grid-column: 1 / 2;
  grid-template-columns: auto;
  grid-row-gap: 10px;
}

#research_consent a {
  color: #00478f;
  text-decoration: none;
}

#research_consent a:hover {
  text-decoration: underline;
}

#research_consent > .value {
  display: flex;
}
#research_consent > .value > .check-box-text {
  font-size: 18px;
  padding-left: 5px;
}

#show-help {
  display: grid;
  grid-row: 3;
  grid-column: 3 / 4;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-row-gap: 10px;
}

.check-box {
  content: url('../img/check_box_outline.svg');
  height: 25px;
  width: 25px;
}

.check-box-selected {
  content: url('../img/check_box.svg');
  height: 25px;
  width: 25px;
}

#show-help > .value {
  display: flex;
}

#show-help > .value > .check-box-text {
  font-size: 18px;
  padding-left: 5px;
}

.performance-report-tabs {
  display: inline-block;
  margin-bottom: 10px;

  border: 1px #999999 solid;
  border-radius: 2%;

  font-size: 14px;

  cursor: pointer;
}

.performance-report-tab {
  display: inline-block;
  padding: 7px;
}

.selected-performance-report-tab {
  display: inline-block;
  padding: 7px;

  color: #ffffff;
  background-color: #0054a6;
}

.performance_report {
  margin-top: 10px;
}

.performance_download_link {
  margin-top: 10px;
}

.words-download-link {
  margin-bottom: 10px;
}

.performance_download_link a,
.words-download-link a {
  color: #00478f;
  text-decoration: none;
}

.performance_download_link a:hover,
.words-download-link a:hover {
  text-decoration: underline;
}

.text_readings_values {
  display: grid;

  margin-top: 10px;
  grid-template-columns: repeat(5, auto);

  grid-row-gap: 15px;
  row-gap: 15px;
}

.text_reading_actions {
  grid-template-columns: auto;
  margin-top: 10px;
}

.text_reading_item {
  margin-bottom: 5px;
}

.profile_item {
  display: grid;

  grid-template-columns: auto;
  margin-bottom: 15px;
  overflow: hidden;
}

.profile_item_title {
  height: 16px;
  border-bottom: solid 0.5px grey;
}

.profile_item_value {
  margin-top: 15px;
}

.update_username {
  margin-top: 10px;
  font-size: 12px;
  color: #00478f;
}

.update_username:hover {
  text-decoration: underline;
}

.username-update-cancel {
  margin-top: 10px;
  color: #00478f;
}

.username-update-cancel:hover {
  text-decoration: underline;
}

.valid_username:after {
  content: '✓';
  color: #26b72b;
  padding-left: 5px;
}

.invalid_username:after {
  content: '✖';
  color: #f00;
  padding-left: 5px;
}

.difficulty {
  margin-bottom: 15px;
}

.difficulty > .title {
  font-size: larger;
}

.category {
  margin-top: 5px;
}

.category > .title {
  font-style: italic;
}

.metric {
  margin-top: 5px;
}

.metric > .title {
  font-size: smaller;
}

.username_msg {
  margin-top: 5px;
}

.username_submit {
  margin-top: 5px;
  color: #00478f;
}

.username_submit > span {
  margin-right: 7px;
}

.username_submit > span:hover {
  text-decoration: underline;
}

#username_hint {
  bottom: 200px;
}

#my_performance_hint {
  bottom: 220px;
}

#preferred_difficulty_hint {
  bottom: 280px;
}

#username_menu_item_hint {
  left: 200px;
  top: 130px;
}

#search_text_menu_item_hint {
  top: 50px;
}

@media (max-width: 330px) {
  .performance-report-tabs {
    display: grid;
  }

  .performance-report-tab {
    display: grid;
    justify-content: center;
  }

  .selected-performance-report-tab {
    display: grid;
    justify-content: center;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .feedback {
    order: 7;
  }

  .filter {
    display: none;
  }

  .footer_items {
    margin-top: 0;
  }

  #lower-menu {
    margin-top: 15px;
  }

  .performance {
    order: 6;
  }

  .preferred_difficulty {
    order: 3;
  }

  .profile {
    margin-left: 0%;
    margin-right: 0%;
    font-size: 11px;
    grid-template-columns: 10% [content] auto 10%;
  }

  .profile-item {
    order: 1;
  }

  .profile_items {
    display: flex;
    flex-direction: column;
    border: none;
    margin-top: 15px;
  }

  .profile-title {
    padding: 0 0 0 25px;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
  }

  .menu > .menu_item {
    padding-left: 5px;
    padding-right: 5px;
  }

  #research_consent {
    order: 4;
  }

  .show-help {
    order: 2;
  }

  #words {
    order: 5;
  }
}
